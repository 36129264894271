import React from 'react'

import { SeasonJSONViewOmittedValues, Season } from 'model/Season'
import Container from 'shared/components/Container'
import Text from 'shared/components/Text'
import ResourceTabs from 'shared/components/Tabs/ResourceTabs'
import PageTitle from 'shared/components/PageTitle'
import { seriesLink } from 'routing/routes'
import Breadcrumbs from 'shared/components/Breadcrumbs'

import Cast from 'shared/components/Cast'

import MasterData from './MasterData'
import Episodes from './Episodes'
import { useResourceMachine } from 'shared/resource/ResourceMachineProvider'
import { JSONViewer } from 'shared/components/JSONViewer'

const SeasonNode = (): React.ReactElement => {
    const [state] = useResourceMachine<Season>()
    const {
        context: { resource },
    } = state

    const {
        seriesCwmClassTypeLabel,
        static: { seriesId, seriesTitle },
        seasonTitle,
        cast,
        episodes,
    } = resource

    return (
        <Container px="3" pb="3" pt="1" flexDirection="column">
            <Breadcrumbs
                items={[
                    { title: seriesCwmClassTypeLabel },
                    { title: seriesTitle, link: seriesLink({ seriesId }) },
                    { title: 'Season' },
                ]}
            />
            <PageTitle title={seasonTitle ?? 'Unknown season'} />
            <Container flexDirection="row" justifyContent="space-between" alignItems="center">
                <Text size="1" as="h2">
                    {seasonTitle}
                </Text>
            </Container>
            <ResourceTabs
                tabs={[
                    {
                        id: 'Master Data',
                        panel: <MasterData />,
                    },
                    {
                        id: 'Episodes',
                        panel: <Episodes data={episodes} />,
                    },
                    {
                        id: 'Cast',
                        panel: <Cast data={cast} />,
                    },
                    {
                        id: 'JSON',
                        panel: <JSONViewer json={resource} omit={SeasonJSONViewOmittedValues} />,
                    },
                ]}
            />
        </Container>
    )
}

export default SeasonNode

import React from 'react'
import { Formik } from 'formik'
import Container from 'shared/components/Container'
import { useInterpreter, useSelector } from './CreateAssignmentProvider'
import { useTaskOptionsForSelectedTitles } from 'model/workflow/TaskType'
import { createAssignmentConfigurationSchema } from 'model/workflow/AssignmentConfiguration'
import CreateAssignmentsStepper from './CreateAssignmentsStepper'
import Button from 'shared/components/Button'
import { useIsXStateTransitionAvailable } from 'xstate-helpers/react/useIsXStateTransitionAvailable'
import SelectedTitlesTable from './AssignmentsConfiguration/SelectedTitlesTable'
import GlobalConfigurationFormNode from './AssignmentsConfiguration/GlobalConfigurationFormNode'

const AssignmentsConfigurationNode: React.FC = () => {
    const service = useInterpreter()
    const selection = useSelector(React.useCallback(state => state.context.selection, []))
    const selectedTitles = selection.map(s => s.title)

    const taskTypeOptions = useTaskOptionsForSelectedTitles(selectedTitles)

    const globalConfiguration = useSelector(
        React.useCallback(state => state.context.globalConfiguration, []),
    )
    const backButtonAvailable = useIsXStateTransitionAvailable(service, 'RETURN_TO_SELECTION')
    const forwardButtonAvailable = useIsXStateTransitionAvailable(service, 'START_CREATION')

    return (
        <Formik
            enableReinitialize
            initialValues={globalConfiguration}
            onSubmit={() => {
                service.send({ type: 'START_CREATION' })
            }}
            validationSchema={createAssignmentConfigurationSchema(selectedTitles)}
        >
            {({ submitForm }) => (
                <>
                    <CreateAssignmentsStepper activeStep={2}>
                        <Container mt={2}>
                            <Container flex="1">
                                <Button
                                    type="button"
                                    size="large"
                                    onClick={() => service.send({ type: 'RETURN_TO_SELECTION' })}
                                    disabled={!backButtonAvailable}
                                >
                                    Back
                                </Button>
                            </Container>

                            <Container>
                                <Button
                                    type="submit"
                                    size="large"
                                    onClick={submitForm}
                                    disabled={!forwardButtonAvailable}
                                >
                                    Create
                                </Button>
                            </Container>
                        </Container>
                    </CreateAssignmentsStepper>

                    <Container
                        flexDirection="column"
                        style={{ maxWidth: '70%', margin: '40px auto 0' }}
                    >
                        <SelectedTitlesTable {...{ selection }} />
                    </Container>

                    <GlobalConfigurationFormNode taskTypeOptions={taskTypeOptions} />
                </>
            )}
        </Formik>
    )
}

export default AssignmentsConfigurationNode

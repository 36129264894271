import { request } from '@genome-web-forms/common/api'
import { MyIDUser } from '@genome-web-forms/common/auth'
import {
    CreateWorkflowRequestInput,
    CreativeWorkTaggingEvent,
    CREATIVE_WORK_QA_AVAILABLE,
    CREATIVE_WORK_TAGGING_AVAILABLE,
    Workflow,
    WorkflowDec,
} from '@genome-web-forms/server'
import { authGWF } from 'api/auth'
import { useUser } from 'auth/Auth'
import * as D from 'io-ts/lib/Decoder'
import { omit } from 'lodash'
import React from 'react'
import { useMutation, useQuery, UseQueryResult } from 'react-query'
import { useQueryClient } from 'react-query/react'
import config from 'shared/config'
import { queryClient } from 'shared/queryClient'
import { DistributiveOmit } from 'shared/types'
import invariant from 'tiny-invariant'

export type WorkflowAssignmentTableType = 'SELF' | 'TAGGING' | 'QA' | 'ALL'

const workflowURLs = {
    // query
    all: `${config.urlGWFWorkflows}/workflows`,
    one: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${encodeURIComponent(workflowId)}`,
    activeByResourceId: (resourceId: Workflow['resourceId']) =>
        `${config.urlGWFWorkflows}/workflows/active-by-resource-id/${encodeURIComponent(
            resourceId,
        )}`,
    // mutate
    selfAssign: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/self-assign`,
    start: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/start`,
    pause: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/pause`,
    resume: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/resume`,
    taggingComplete: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/tagging-complete`,
    taggingAbandon: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/tagging-abandon`,
    publishStart: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/publish-start`,
    publishSuccess: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/publish-success`,
    publishError: (workflowId: Workflow['workflowId']) =>
        `${config.urlGWFWorkflows}/workflows/${workflowId}/publish-error`,
} as const

const workflowKeys = {
    all: [{ scope: 'workflows' }],
    activeByResourceId: (resourceId: string) => [{ scope: 'workflows', active: true, resourceId }],
} as const

export const createAssignment = (
    createRequest: CreateWorkflowRequestInput,
    user: MyIDUser,
): Promise<Workflow> => {
    return request(
        WorkflowDec,
        authGWF(user, {
            url: workflowURLs.all,
            method: 'POST',
            data: createRequest,
        }),
    )
}

export const WORKFLOW_ACTIONS = {
    SELF_ASSIGN: workflowURLs.selfAssign,
    START: workflowURLs.start,
    PAUSE: workflowURLs.pause,
    RESUME: workflowURLs.resume,
    TAGGING_COMPLETE: workflowURLs.taggingComplete,
    TAGGING_ABANDON: workflowURLs.taggingAbandon,
    PUBLISH_START: workflowURLs.publishStart,
    PUBLISH_SUCCESS: workflowURLs.publishSuccess,
    PUBLISH_ERROR: workflowURLs.publishError,
} as const

export type WorkflowActionEvent = DistributiveOmit<
    Extract<CreativeWorkTaggingEvent, { type: keyof typeof WORKFLOW_ACTIONS }>,
    'user'
>

export const workflowAction = async (
    user: MyIDUser,
    workflowId: Workflow['workflowId'],
    event: WorkflowActionEvent,
): Promise<Workflow> => {
    const url = WORKFLOW_ACTIONS[event.type]
    invariant(url)
    const res = await request(
        WorkflowDec,
        authGWF(user, {
            method: 'PUT',
            url: url(workflowId),
            data: omit(event, ['user', 'type']),
        }),
    )

    await queryClient.invalidateQueries(workflowKeys.all)

    return res
}

// eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types
export const useSelfAssignWorkflow = () => {
    const user = useUser()
    const queryClient = useQueryClient()
    return useMutation<Workflow, unknown, Workflow>({
        mutationFn: workflow => workflowAction(user, workflow.workflowId, { type: 'SELF_ASSIGN' }),
        onMutate: async _ => {
            await queryClient.cancelQueries(workflowKeys.all)
        },
        onSettled: () => {
            queryClient.invalidateQueries(workflowKeys.all)
        },
    })
}

// eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types
export const useDeleteWorkflow = () => {
    const user = useUser()
    const queryClient = useQueryClient()
    return useMutation<Workflow, unknown, Workflow, { previousWorkflows: Workflow[] }>({
        mutationFn: workflowToDelete => {
            return request(
                WorkflowDec,
                authGWF(user, {
                    method: 'DELETE',
                    url: workflowURLs.one(workflowToDelete.workflowId),
                }),
            )
        },
        onMutate: async workflowToDelete => {
            await queryClient.cancelQueries(workflowKeys.all)

            const previousWorkflows: Workflow[] = queryClient.getQueryData(workflowKeys.all) ?? []

            queryClient.setQueryData(
                workflowKeys.all,
                (workflows?: Workflow[]) =>
                    workflows?.filter(workflow => workflow !== workflowToDelete) ?? [],
            )
            return { previousWorkflows }
        },
        onError: (_err, _workflow, context) => {
            if (context) {
                queryClient.setQueryData(workflowKeys.all, context.previousWorkflows)
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(workflowKeys.all)
        },
    })
}

export const fetchActiveWorkflowsByResourceId = (
    user: MyIDUser,
    resourceId: string,
): Promise<Workflow[]> => {
    return request(
        D.array(WorkflowDec),
        authGWF(user, {
            url: workflowURLs.activeByResourceId(resourceId),
        }),
    )
}

export const useWorkflowsQuery = (
    assignmentType: WorkflowAssignmentTableType = 'ALL',
): UseQueryResult<Workflow[]> => {
    const user = useUser()
    return useQuery(workflowKeys.all, () => fetchWorkflowsList(user), {
        select: React.useCallback(
            (workflows: Workflow[]) => {
                switch (assignmentType) {
                    case 'SELF':
                        return workflows.filter(w => w.assignee === user['relationship.employeeId'])
                    case 'TAGGING':
                        return workflows.filter(w => w.state === CREATIVE_WORK_TAGGING_AVAILABLE)
                    case 'QA':
                        return workflows.filter(w => w.state === CREATIVE_WORK_QA_AVAILABLE)
                    case 'ALL':
                    default:
                        return workflows
                }
            },
            [user, assignmentType],
        ),
    })
}

export const useWorkflowQuery = (workflowId: number): UseQueryResult<Workflow> => {
    const user = useUser()
    return useQuery(workflowURLs.one(workflowId), () => fetchWorkflow(user, workflowId))
}

export const fetchWorkflowsList = (user: MyIDUser): Promise<Workflow[]> => {
    return request(
        D.array(WorkflowDec),
        authGWF(user, {
            url: workflowURLs.all,
        }),
    )
}

export const fetchWorkflow = (user: MyIDUser, workflowId: number): Promise<Workflow> => {
    return request(
        WorkflowDec,
        authGWF(user, {
            url: workflowURLs.one(workflowId),
        }),
    )
}

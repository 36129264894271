import React from 'react'
import { Helmet } from 'react-helmet-async'

interface Props {
    title?: string
}

const PageTitle = ({ title }: Props): React.ReactElement => (
    <Helmet>
        <title>{title ? `${title} - ` : ''}Genome Web Forms</title>
    </Helmet>
)

export default PageTitle

import type { CreateWorkflowRequestInput } from '@genome-web-forms/server'
import { omit } from 'lodash'
import { AssignmentConfiguration } from './AssignmentConfiguration'
import { getValidTaskTypesPredicate } from './TaskType'

export type AssignmentTitle = {
    resourceId: string
    resourceTitle: string
    cwmClassType: string
    cwmClassTypeLabel: string
}

export const mergeAssignmentTitleConfiguration = (
    title: AssignmentTitle,
    titleConfiguration: Partial<AssignmentConfiguration>,
    globalConfiguration: AssignmentConfiguration,
): AssignmentConfiguration => {
    const tasks = (
        titleConfiguration.tasks?.length ? titleConfiguration.tasks : globalConfiguration.tasks
    ).filter(getValidTaskTypesPredicate(title))

    return {
        ...globalConfiguration,
        ...titleConfiguration,
        tasks,
    }
}

export const createRequestsFromTitleAndConfiguration = ({
    title,
    mergedConfiguration,
}: {
    title: AssignmentTitle
    mergedConfiguration: AssignmentConfiguration
}): CreateWorkflowRequestInput[] => {
    const requests: CreateWorkflowRequestInput[] = []

    for (const task of mergedConfiguration.tasks) {
        const request: CreateWorkflowRequestInput = {
            workflowType: 'CREATIVE_WORK_TAGGING',
            ...title,
            workflowConfig: { task },
            ...omit(mergedConfiguration, 'tasks'),
        }
        requests.push(request)
    }
    return requests
}

import React from 'react'

import { Feature } from 'model/Feature'
import Panel from 'shared/components/Panel'
import Text from 'shared/components/Text'
import Optional from 'shared/components/Optional'
import { ReleaseDate } from 'shared/components/FormattedDate'
import { useResourceMachineSelector } from 'shared/resource/ResourceMachineProvider'
import { GridWithColumns, Grid } from 'shared/components/MasterData/Grid'
import BooleanValue from 'shared/components/MasterData/BooleanValue'
import Status from 'shared/components/MasterData/Status'
import ListSectionDivider from 'shared/components/MasterData/ListSectionDivider'
import CopyText from 'shared/components/CopyText'
import { flags } from 'shared/flags'

const MasterData: React.FC = (): React.ReactElement => {
    const {
        featureId,
        featureTitle,
        static: {
            titleNameDisambiguated,
            titleNameStandardized,
            earliestReleaseDate,
            productId,
            productNumber,
            groupId,
            // _unverified_radarGroupNumber,
            eidrL1,
            hasMDMID,
            creativeFormat,
            brandTitle,
            productionCompany,
            // _unverified_rating,
            // _unverified_runtime,
            disneyPlusSelected,
            DisneyPlusOriginal,
            synopsisMedium,
            starSelected,
        },
    } = useResourceMachineSelector<Feature, Feature>(
        React.useCallback(state => state.context.resource, []),
    )

    return (
        <>
            <GridWithColumns>
                <Panel>
                    <Text as="h3" size="3" variant="secondary" mb="2">
                        Title Info
                    </Text>
                    <dl>
                        <dt>Release date</dt>
                        <dd>
                            <ReleaseDate releaseDate={earliestReleaseDate} />
                        </dd>
                        <ListSectionDivider />
                        <dt>Title name</dt>
                        <dd>{featureTitle}</dd>
                        <dt>Disambiguated name</dt>
                        <dd>
                            <Optional value={titleNameDisambiguated} />
                        </dd>
                        <dt>Standardized name</dt>
                        <dd>
                            <Optional value={titleNameStandardized} />
                        </dd>
                        <ListSectionDivider />
                        <dt>Creative format</dt>
                        <dd>
                            <Optional value={creativeFormat} />
                        </dd>
                        <dt>Brand</dt>
                        <dd>
                            <Optional value={brandTitle} />
                        </dd>
                        <dt>Franchise</dt>
                        <dd>
                            <Optional value={productionCompany} />
                        </dd>
                        <ListSectionDivider />
                        {/*
                        <dt>Rating</dt>
                        <dd>
                            <Optional value={_unverified_rating} />
                        </dd>
                        <dt>Runtime</dt>
                        <dd>
                            <Optional value={_unverified_runtime} />
                        </dd>
                        <ListSectionDivider />
                        */}
                        <dt>Disney Plus Selected</dt>
                        <dd>
                            <BooleanValue value={disneyPlusSelected} />
                        </dd>
                        <dt>Disney Plus Original</dt>
                        <dd>
                            <BooleanValue value={DisneyPlusOriginal} />
                        </dd>
                        {flags.starSelected && (
                            <>
                                <dt>Star Selected</dt>
                                <dd>
                                    <BooleanValue value={starSelected} />
                                </dd>
                            </>
                        )}
                        <dt>Releasing company</dt>
                        <dd>
                            <Optional value={productionCompany} />
                        </dd>
                        <dt>Production Company/ies</dt>
                        <dd>
                            <Optional value={productionCompany} />
                        </dd>
                    </dl>
                </Panel>
                <Grid>
                    <Panel>
                        <Text as="h3" size="3" variant="secondary" mb="2">
                            Title IDs
                        </Text>
                        <dl>
                            <dt>EIDR L1 ID</dt>
                            <dd>
                                <Optional value={eidrL1} />
                            </dd>
                            <dt>RADAR Product ID</dt>
                            <dd>
                                <Optional value={productId} />
                            </dd>
                            <dt>RADAR Product Number</dt>
                            <dd>
                                <Optional value={productNumber} />
                            </dd>
                            <dt>RADAR Group ID</dt>
                            <dd>
                                <Optional value={groupId} />
                            </dd>
                            {/*
                            <dt>RADAR Group Number</dt>
                            <dd>
                                <Optional value={_unverified_radarGroupNumber} />
                            </dd>
                            */}
                            <dt>CTAM MDM ID</dt>
                            <dd>
                                <Optional value={hasMDMID} />
                            </dd>
                            <dt>Genome ID</dt>
                            <dd style={{ overflowX: 'hidden' }}>
                                <CopyText style={{ whiteSpace: 'nowrap' }} text={featureId} />
                            </dd>
                        </dl>
                    </Panel>
                    <Panel>
                        <Text as="h3" size="3" variant="secondary" mb="2">
                            Status
                        </Text>
                        <Status />
                    </Panel>
                </Grid>
                <Grid>
                    <Panel>
                        <Text as="h3" size="3" variant="secondary" mb="2">
                            Synopsis
                        </Text>
                        {synopsisMedium}
                    </Panel>
                </Grid>
            </GridWithColumns>
        </>
    )
}

export default MasterData

import React from 'react'
import * as t from 'io-ts'
import * as cwm from '@genome-web-forms/common/model/CWMClassType'
import uniq from 'lodash/uniq'
import flatMap from 'lodash/flatMap'
import { pipe } from 'fp-ts/lib/function'
import invariant from 'tiny-invariant'
import {
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA,
    WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS,
} from '@genome-web-forms/server'

export const TaskTypeCodec = t.keyof({
    [WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA]: null,
    [WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS]: null,
})
export type TaskType = t.TypeOf<typeof TaskTypeCodec>

//////////////////////////////////////////////////////

export type TaskTypeOption = { value: TaskType; label: string }

export const TASK_TYPE_OPTIONS_ALL: TaskTypeOption[] = [
    {
        value: WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA,
        label: 'Metadata',
    },
    {
        value: WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS,
        label: 'Storylines / Relationships',
    },
]

export const getTaskLabel = (task: TaskType): string => {
    const option = TASK_TYPE_OPTIONS_ALL.find(o => o.value === task)
    invariant(option, `Did not find option for TaskType "${task}"`)
    return option.label
}

export const TASK_TYPES_PER_CWM_BASE_TYPE: Record<cwm.CWMBaseClass, TaskType[]> = {
    feature: [
        WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA,
        WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS,
    ],
    episode: [
        WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA,
        WORKFLOW_CREATIVE_WORK_TAGGING_TASK_STORYLINES_RELATIONSHIPS,
    ],
    series: [WORKFLOW_CREATIVE_WORK_TAGGING_TASK_METADATA],
}

export const getTaskOptionsForSelectedTitles = (
    selectedTitles: { cwmClassType: string }[],
): TaskTypeOption[] => {
    const tasksCwmBaseClasses = pipe(
        selectedTitles.map(t => cwm.getCWMBaseClass(t)),
        uniq,
    )
    const taskTypes: TaskType[] = pipe(
        flatMap(tasksCwmBaseClasses, c => TASK_TYPES_PER_CWM_BASE_TYPE[c]),
        uniq,
    )

    return TASK_TYPE_OPTIONS_ALL.filter(o => taskTypes.includes(o.value))
}

export const getValidTaskTypesPredicate =
    (title: { cwmClassType: string }): ((task: TaskType) => boolean) =>
    task =>
        getAvailableTaskTypesForTitle(title).includes(task)

export const getAvailableTaskTypesForTitle = (title: { cwmClassType: string }): TaskType[] => {
    return TASK_TYPES_PER_CWM_BASE_TYPE[cwm.getCWMBaseClass(title)]
}

export const useTaskOptionsForSelectedTitles = (
    selectedTitles: { cwmClassType: string }[],
): TaskTypeOption[] => {
    return React.useMemo(() => {
        return getTaskOptionsForSelectedTitles(selectedTitles)
    }, [selectedTitles])
}

import { WIPDataType } from 'model/WIP'
import React from 'react'
import { LabelWithLock } from 'shared/components/Tabs/ResourceTabs'
import { useResourceMachineSelector, useWIPActor } from './ResourceMachineProvider'
import { WIPInfoIcon } from './WIPInfo'

/**
 * Treat the WIP Info as a button that tries to aquire the resource on double click
 */
export type WIPInfoAndAquireProps = {
    title: string
    dataType: WIPDataType
}
export const WIPInfoAndAquire: React.FC<WIPInfoAndAquireProps> = ({ title, dataType }) => {
    const [state, send] = useWIPActor(dataType)
    const inEditing = useResourceMachineSelector(state => state.matches('editing'))
    return (
        <LabelWithLock
            title={title}
            onDoubleClick={() => {
                if (inEditing && state.matches('reading')) {
                    send({ type: 'ATTEMPT_AQUIRE' })
                }
            }}
        >
            <WIPInfoIcon state={state} />
        </LabelWithLock>
    )
}

export default WIPInfoAndAquire

import PageLayout from 'pages/util/PageLayout'
import React from 'react'
import { SearchContainer } from 'shared/components/search'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'shared/components/Tabs'
import { useWorkflowAvailableRoleTabs } from 'shared/components/Workflow/useWorkflowTabs'
import AssignmentTable from './AssignmentTable'

const WorkflowAssignmentsList: React.FC = () => {
    const tabs = useWorkflowAvailableRoleTabs()

    return (
        <PageLayout>
            <SearchContainer>
                <Tabs>
                    <TabList>
                        {tabs.map(({ label, id }) => (
                            <Tab key={`workflow-tab-header-${id}`}>{label}</Tab>
                        ))}
                    </TabList>
                    <TabPanels>
                        {tabs.map(({ type, id }) => (
                            <TabPanel key={`workflow-tab-panel-${id}`}>
                                <AssignmentTable type={type}></AssignmentTable>
                            </TabPanel>
                        ))}
                    </TabPanels>
                </Tabs>
            </SearchContainer>
        </PageLayout>
    )
}

export default WorkflowAssignmentsList

import React from 'react'
import capitalize from 'lodash/capitalize'
import { getTaskLabel, TaskType } from 'model/workflow/TaskType'
import { WorkflowPriority } from '@genome-web-forms/server'
import { CellProps, Column } from 'react-table'
import Table, { RowComponentDefault } from 'shared/components/Table'
import Text from 'shared/components/Text'
import { Selection } from '../createAssignment.machine'
import { TableRow, TableCell } from 'shared/components/Table'
import { useInterpreter, useSelector } from '../CreateAssignmentProvider'
import AssignmentTableRowForm from './AssignmentTableRowForm'
import MediumDate from 'shared/components/MediumDate'

type SelectedTitlesTableProps = {
    selection: Selection[]
}
const SelectedTitlesTable: React.FC<SelectedTitlesTableProps> = ({ selection }) => {
    const service = useInterpreter()
    const globalConfiguration = useSelector(
        React.useCallback(state => state.context.globalConfiguration, []),
    )
    const columns = React.useMemo<Column<Selection>[]>(
        () => [
            {
                accessor: s => s.title.resourceTitle,
                Header: 'Title',
                width: 300,
            },
            {
                Header: 'Type',
                accessor: s => s.title.cwmClassTypeLabel,
                width: 2,
                Cell: ({ row: { original } }: CellProps<Selection>) => (
                    <span style={{ whiteSpace: 'nowrap' }}>{original.title.cwmClassTypeLabel}</span>
                ),
            },
            {
                id: 'tasks',
                Header: 'Tasks',
                width: 270,
                accessor: s => s.mergedConfiguration.tasks,
                Cell: ({ value }: CellProps<Selection, TaskType[]>) => {
                    if (value.length === 0) {
                        return <Text variant="danger">No tasks selected</Text>
                    }

                    return (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {value.map(getTaskLabel).join(', ')}
                        </span>
                    )
                },
            },
            {
                id: 'assignee',
                Header: 'Assignee',
                accessor: s => s.mergedConfiguration.assignee,
            },
            {
                Header: 'Priority',
                accessor: s => s.mergedConfiguration.priority,
                Cell: ({ value }: CellProps<Selection, WorkflowPriority | undefined>) =>
                    capitalize(value ?? ''),
                width: 1,
            },
            {
                id: 'deadlineAt',
                Header: 'Publish By Date',
                accessor: s => s.mergedConfiguration.deadlineAt,
                Cell: ({ value }: CellProps<Selection, string | undefined>) => (
                    <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                        {value ? <MediumDate date={new Date(value)} /> : null}
                    </Text>
                ),
            },
        ],
        [],
    )

    return (
        <>
            <Text as="h1">Selected Titles</Text>
            <Table<Selection>
                {...{
                    columns,
                    data: selection,
                    onRowClick: ({ row }) => {
                        if (!row.original.editingConfiguration) {
                            service.send({
                                type: 'TITLE_CONFIGURATION_EDIT_START',
                                title: row.original.title,
                            })
                        }
                    },
                    RowComponent: props => {
                        const selection = props.row.original
                        if (selection.editingConfiguration) {
                            return (
                                <React.Fragment key={props.row.getRowProps().key}>
                                    <RowComponentDefault {...props} />
                                    <TableRow key={'edit-' + props.row.getRowProps().key}>
                                        <TableCell
                                            {...{
                                                colSpan: props.allColumns.length,
                                                style: { padding: 0 },
                                                removeTopBorderRadius: true,
                                                alignWithPreviousRow: true,
                                            }}
                                        >
                                            <AssignmentTableRowForm
                                                {...{
                                                    title: selection.title,
                                                    editingConfiguration:
                                                        selection.editingConfiguration,
                                                    globalConfiguration,
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )
                        }
                        return <RowComponentDefault {...props} />
                    },
                }}
            />
        </>
    )
}
export default SelectedTitlesTable

import React from 'react'
import { Link } from 'react-router-dom'
import { homeLink } from 'routing/routes'
import Text from 'shared/components/Text'
import styled from 'shared/theme'

const HomeLink = styled(Link)`
    margin-right: auto;
    text-decoration: none;
`
const Logo: React.FC<{ inControlMode?: boolean }> = ({ inControlMode }) => (
    <HomeLink to={homeLink}>
        <Text as="h1" size="1" style={{ color: inControlMode ? 'white' : undefined }}>
            Genome
        </Text>
    </HomeLink>
)

export default Logo

import React from 'react'
import styled from 'shared/theme'
import { Form, useFormikContext } from 'formik'
import Container from 'shared/components/Container'
import { FormikInput } from 'shared/components/Input'
import ErrorMessage from 'shared/components/ErrorMessage'
import Autosave from 'shared/form/Autosave'
import CellLabel from 'shared/components/CellLabel'
import FormikSelect from 'shared/components/Select/FormikSelect'
import { TaskTypeOption } from 'model/workflow/TaskType'
import { AssignmentConfiguration } from 'model/workflow/AssignmentConfiguration'
import DatePicker from 'shared/components/DatePicker'
import dateFormat from 'date-fns/format'
import Text from 'shared/components/Text'
import AssigneeSelect from './AssigneeSelect'
import { useInterpreter } from '../CreateAssignmentProvider'
import { WorkflowPriorityOptions } from 'model/workflow/WorkflowPriority'

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 8px;
`

type GlobalConfigurationFormNodeProps = {
    taskTypeOptions: TaskTypeOption[]
}
const GlobalConfigurationFormNode: React.FC<GlobalConfigurationFormNodeProps> = ({
    taskTypeOptions,
}) => {
    const service = useInterpreter()
    const { values, setFieldValue } = useFormikContext<AssignmentConfiguration>()

    return (
        <Container flexDirection="column" style={{ maxWidth: '70%', margin: '40px auto 0' }}>
            <Form>
                <Text as="h1">Global Assignment Configuration</Text>

                <Autosave<AssignmentConfiguration>
                    onChange={globalConfiguration =>
                        service.send({ type: 'GLOBAL_CONFIGURATION_UPDATE', globalConfiguration })
                    }
                />

                <br />

                <InputWrapper>
                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                        Tasks
                    </CellLabel>

                    <FormikSelect
                        name="tasks"
                        isMulti
                        options={taskTypeOptions}
                        value={taskTypeOptions.filter(tto => values.tasks.includes(tto.value))}
                        onChange={
                            ((value: { value: string }[]) => {
                                setFieldValue('tasks', value ? value.map(v => v.value) : [])
                            }) as any
                        }
                    />
                    <ErrorMessage name="tasks" />
                </InputWrapper>

                <InputWrapper>
                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                        Assignee
                    </CellLabel>

                    <AssigneeSelect name="assignee" />
                    <ErrorMessage name="assignee" />
                </InputWrapper>

                <InputWrapper>
                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                        Priority
                    </CellLabel>

                    <FormikSelect
                        name="priority"
                        isClearable={true}
                        options={WorkflowPriorityOptions}
                        value={WorkflowPriorityOptions.find(po => po.value === values.priority)}
                        onChange={
                            ((value: { value: string } | null) =>
                                setFieldValue('priority', value ? value.value : null)) as any
                        }
                        placeholder="Default priority"
                    />
                    <ErrorMessage name="priority" />
                </InputWrapper>

                <InputWrapper>
                    <CellLabel size="6" variant="secondary" weight="bold" mb="1">
                        Publish by date
                    </CellLabel>

                    <DatePicker
                        selected={values.deadlineAt ? new Date(values.deadlineAt) : undefined}
                        minDate={new Date()}
                        onChange={date =>
                            setFieldValue(
                                'deadlineAt',
                                date ? dateFormat(date as Date, 'yyyy-MM-dd') : undefined,
                            )
                        }
                    />
                    <ErrorMessage name="deadlineAt" />
                </InputWrapper>

                <InputWrapper>
                    <CellLabel
                        size="6"
                        variant="secondary"
                        weight="bold"
                        mb="1"
                        htmlFor="form-comment"
                    >
                        Comment
                    </CellLabel>

                    <FormikInput
                        id="form-comment"
                        placeholder="Comment to display to the Tagger"
                        name="comment"
                    />
                    <ErrorMessage name="description" />
                </InputWrapper>
            </Form>
        </Container>
    )
}

export default GlobalConfigurationFormNode

import React from 'react'
import { useMachine } from '@xstate/react'
import { useUser } from 'auth/Auth'
import { Formik, Field } from 'formik'
import ErrorMessage from 'shared/components/ErrorMessage'
import fromPairs from 'lodash/fromPairs'
import Panel from 'shared/components/Panel'
import Text from 'shared/components/Text'
import { useResourceMachineSelector } from 'shared/resource/ResourceMachineProvider'
import Button from 'shared/components/Button'
import { EditIconButton } from 'shared/components/Icons/EditIcon'
import LinkifiedText from 'shared/components/LinkifiedText'
import Loader from 'shared/components/Loader'
import { createMetadataDescriptionMachine, events } from './metadataDescription.machine'
import { ResourceType } from 'api/fetch/fetchResource'
import mapValues from 'lodash/mapValues'
import { useStateCan } from 'xstate-helpers/react'
import { hasPermission } from 'shared/components/UserHasPermission'
import { PERMISSION_WRITE } from '@genome-web-forms/common/auth'

function MetadataDescription(): JSX.Element | null {
    const user = useUser()

    const { resourceId, resourceType } = useResourceMachineSelector<
        any,
        { resourceId: string; resourceType: ResourceType }
    >(
        React.useCallback(
            state => ({
                resourceId: state.context.resourceId,
                resourceType: state.context.resourceType,
            }),
            [],
        ),
    )
    const [state, send, service] = useMachine(
        () => createMetadataDescriptionMachine({ user, resourceId, resourceType }),
        {
            context: {
                user,
                resourceId,
            },
            devTools: true,
        },
    )
    const isLoading = state.matches('loading')
    const isEditable = useStateCan(service, 'EDIT_START')
    const isEditing = state.matches('editing') || state.matches('saving')
    const isSaving = state.matches('saving')

    const { metadataDescription, errors, editContent } = state.context
    const initialValues = { editContent, __general: '', __updatedWhileEditing: '' }
    const formikErrors = backendToFormikErrors(errors)
    const formikTouched = mapValues(formikErrors, () => true)

    if (!hasPermission(user, PERMISSION_WRITE)) {
        return null
    }

    return (
        <Panel gray-bg data-test-id="metadata-description">
            <div>
                <Text as="h3" size="3" variant="secondary" mb="2">
                    Additional Title Info / Notes {isLoading && <Loader inline size="tiny" />}
                    {isEditable && (
                        <EditIconButton
                            label="Edit additional title info"
                            onClick={() => send('EDIT_START')}
                        />
                    )}
                </Text>
            </div>

            {isEditing ? (
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    initialErrors={formikErrors}
                    initialTouched={formikTouched}
                    onSubmit={({ editContent }) => {
                        send(events.EDIT_COMMIT(editContent))
                    }}
                >
                    {({ submitForm }) => (
                        <div>
                            <ErrorMessage name="__general" />
                            {errors.__updatedWhileEditing && (
                                <div style={{ color: 'red' }}>
                                    <p>Data was changed by someone else.</p>
                                    <p>New text is:</p>
                                    <LinkifiedText>{errors.__updatedWhileEditing}</LinkifiedText>
                                    <br />
                                </div>
                            )}
                            <ErrorMessage name="editContent" />
                            <ErrorMessage name="content" />
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    marginBottom: '1rem',
                                }}
                            >
                                <Button
                                    variant="outline"
                                    type="button"
                                    size="small"
                                    disabled={isSaving}
                                    onClick={() => send('EDIT_CANCEL')}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    disabled={isSaving}
                                    isLoading={isSaving}
                                    size="small"
                                    type="button"
                                    onClick={submitForm}
                                >
                                    {isSaving ? 'Saving...' : 'Save'}
                                </Button>
                            </div>
                            <Field
                                as="textarea"
                                style={{ width: '100%' }}
                                rows="20"
                                name="editContent"
                                disabled={isSaving}
                            />
                        </div>
                    )}
                </Formik>
            ) : (
                <Text as="div" variant="secondary">
                    {metadataDescription?.content ? (
                        <LinkifiedText>{metadataDescription.content}</LinkifiedText>
                    ) : state.matches('loading') ? (
                        <em>Loading...</em>
                    ) : state.matches('idle') ? (
                        <em>No title info added for this title.</em>
                    ) : state.matches('errorLoading') ? (
                        <div>
                            <em>Error loading title info</em>
                            <Button
                                type="button"
                                variant="outlineNoBorders"
                                size="small"
                                onClick={() => send('LOAD_RETRY')}
                            >
                                Retry
                            </Button>
                        </div>
                    ) : (
                        ''
                    )}
                </Text>
            )}
        </Panel>
    )
}

function backendToFormikErrors<T extends Record<string, string | Array<string>>>(
    errors: T,
): Record<string, string> {
    let result = fromPairs(
        Object.keys(errors).map(key => [
            key,
            Array.isArray(errors[key])
                ? (errors[key] as Array<string>).join(', ')
                : '' + errors[key],
        ]),
    )
    result._________HACK_TO_MAKE_FORMIK_ALWAYS_RERENDER_THE_ERRORS = +new Date() + ''
    return result
}

export default MetadataDescription

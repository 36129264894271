import { WorkflowHelper } from '@genome-web-forms/server'
import { MenuItem, MenuList } from 'shared/components/Menu'
import { Menu, MenuButton } from '@reach/menu-button'
import { useSelector } from '@xstate/react'
import React from 'react'
import Container from 'shared/components/Container'
import NoWrap from 'shared/components/NoWrap'
import {
    useResourceMachineSelector,
    useResourceMachineService,
} from 'shared/resource/ResourceMachineProvider'
import { WorkflowMachineActor } from 'shared/resource/workflow.machine'
import { EmittedFrom } from 'xstate'
import { useStateCan } from 'xstate-helpers/react'

type WorkflowControlProps = { workflowActorRef: WorkflowMachineActor }
const WorkflowControl = ({ workflowActorRef }: WorkflowControlProps): React.ReactElement | null => {
    const workflow = useSelector(
        workflowActorRef,
        React.useCallback(
            (state: EmittedFrom<typeof workflowActorRef>) => state.context.workflow,
            [],
        ),
    )
    const service = useResourceMachineService()

    const canStartWorkflow = useStateCan(service, { type: 'WORKFLOW_START', workflow })
    const canContinueWorkflow = useStateCan(service, { type: 'WORKFLOW_CONTINUE', workflow })
    const canCompleteActiveWorfklow = useStateCan(service, {
        type: 'WORKFLOW_COMPLETE_ACTIVE_WORKFLOW',
        workflow,
    })
    const canPause = useStateCan(service, { type: 'WORKFLOW_PAUSE', workflow })
    const canResume = useStateCan(service, { type: 'WORKFLOW_RESUME', workflow })
    const canAbandon = useStateCan(service, { type: 'WORKFLOW_ABANDON', workflow })
    const canPublish = useStateCan(service, { type: 'WORKFLOW_PUBLISH', workflow })

    const buttons: JSX.Element[] = []

    if (canPublish) {
        buttons.push(
            <MenuItem
                key="publish"
                onSelect={() => service.send({ type: 'WORKFLOW_PUBLISH', workflow })}
            >
                Publish Workflow
            </MenuItem>,
        )
    }

    if (canAbandon) {
        buttons.push(
            <MenuItem
                key="abandon"
                onSelect={() => service.send({ type: 'WORKFLOW_ABANDON', workflow })}
            >
                Abandon Workflow
            </MenuItem>,
        )
    }

    if (canResume) {
        buttons.push(
            <MenuItem
                onSelect={() => service.send({ type: 'WORKFLOW_RESUME', workflow })}
                key="resume"
            >
                Resume Workflow
            </MenuItem>,
        )
    }

    if (canPause) {
        buttons.push(
            <MenuItem
                key="pause"
                onSelect={() => service.send({ type: 'WORKFLOW_PAUSE', workflow })}
            >
                Pause Workflow
            </MenuItem>,
        )
    }

    if (canStartWorkflow) {
        buttons.push(
            <MenuItem
                key="start"
                onSelect={() => service.send({ type: 'WORKFLOW_START', workflow })}
            >
                Start Work
            </MenuItem>,
        )
    }

    if (canContinueWorkflow) {
        buttons.push(
            <MenuItem
                onSelect={() => service.send({ type: 'WORKFLOW_CONTINUE', workflow })}
                key="continue"
            >
                Continue Working
            </MenuItem>,
        )
    }

    if (canCompleteActiveWorfklow) {
        buttons.push(
            <MenuItem
                onSelect={() =>
                    service.send({ type: 'WORKFLOW_COMPLETE_ACTIVE_WORKFLOW', workflow })
                }
                key="complete-active"
            >
                Complete Workflow
            </MenuItem>,
        )
    }

    if (!buttons.length) {
        return null
    }

    return (
        <Menu>
            <MenuButton>
                <NoWrap>
                    {WorkflowHelper.title(workflow)} actions <span aria-hidden>▾</span>
                </NoWrap>
            </MenuButton>
            <MenuList>{buttons}</MenuList>
        </Menu>
    )
}

const AllWorkflowControls = (): React.ReactElement | null => {
    // TODO react.usecallback
    const workflowActors = useResourceMachineSelector(state => state.context.workflowRefs)
    if (!workflowActors.length) return null

    return (
        <Container flex="1" justifyContent="flex-end" pr="2">
            {workflowActors
                .map(workflowActorRef => (
                    <WorkflowControl
                        key={workflowActorRef.getSnapshot()!.context.workflow.workflowId}
                        workflowActorRef={workflowActorRef}
                    />
                ))
                .filter(Boolean)}
        </Container>
    )
}

export default AllWorkflowControls

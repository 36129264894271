import React from 'react'
import styled from 'shared/theme'
import SearchTypeSelect from 'pages/Search/SearchTypeSelect'
import Loader from 'shared/components/Loader'
import { TableNode } from 'shared/components/Table'
import Container from 'shared/components/Container'
import { labelForValue } from 'model/search/SearchOptions'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import {
    useTable,
    useRowSelect,
    Column,
    CellProps,
    TableInstance,
    TableRowComponentProps,
} from 'react-table'
import {
    SearchContainer,
    SearchForm,
    SearchResultsHeader,
    SearchResultTitle,
    SearchHeaderText,
    SearchInput,
} from 'shared/components/search'
import IndeterminateCheckbox from 'shared/components/IndeterminateCheckbox'
import { useEventCallback } from 'shared/hooks/useEventCallback'
import omit from 'lodash/omit'

import { useSearch } from 'shared/search/useSearch'
import { SearchResult, getTitle } from 'model/search/SearchResult'
import differenceBy from 'lodash/differenceBy'
import { When } from 'react-if'

import { AssignmentTitle } from 'model/workflow/CreateWorkflowRequest'
import { useSelector, useInterpreter } from './CreateAssignmentProvider'

const SearchResultsTableWrapper = styled.div`
    th:first-child,
    td:first-child {
        width: 0.8125rem;
    }
`

type WorkflowTitleSearch = AssignmentTitle & { searchResult: SearchResult }

export const TitleSearch: React.FC = (): React.ReactElement => {
    const service = useInterpreter()
    const upstreamTitles = useSelector(React.useCallback(state => state.context.selection, [])).map(
        s => s.title,
    )
    const upstreamTitlesRef = React.useRef(upstreamTitles)
    upstreamTitlesRef.current = upstreamTitles

    const {
        isLoading,
        results: _raw_search_results,
        searchType,
        searchText,
        lastSearchedText,
        setSearchText,
        setSearchType,
    } = useSearch({})

    const searchResults = React.useMemo(
        () => _raw_search_results.map(searchResultToWorkflowTitle),
        [_raw_search_results],
    )

    const [selectedTitles, _setSelectedTitles] = React.useState<WorkflowTitleSearch[]>([])
    const setSelectedTitles = React.useCallback<typeof _setSelectedTitles>(v => {
        _setSelectedTitles(selectedTitles => {
            return differenceBy(
                typeof v === 'function' ? v(selectedTitles) : v,
                upstreamTitlesRef.current,
                t => t.resourceId,
            )
        })
    }, [])

    const addSelection = useEventCallback((selectedTitles: WorkflowTitleSearch[]): void => {
        const titles = selectedTitles.map(t => omit(t, 'searchResult'))
        service.send({ type: 'ADD_SELECTED_TITLES', titles })
    })

    const table = useTitleSelectionTable({
        selectedTitles,
        searchResults,
        upstreamTitles,
        setSelectedTitles,
        addSelection,
    })

    const executeAddSelection = (): void => {
        addSelection(selectedTitles)
        setSelectedTitles([])
    }

    return (
        <SearchContainer>
            <SearchHeaderText size="2" as="h2" mt="2" mr="2">
                Search for titles
            </SearchHeaderText>

            <SearchForm>
                <SearchTypeSelect
                    searchType={searchType}
                    onChange={searchType => setSearchType(searchType)}
                />

                <SearchInput
                    autoFocus
                    placeholder="Start typing to search"
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                    onReset={() => setSearchText('')}
                />
            </SearchForm>

            <When condition={lastSearchedText}>
                <SearchResultsHeader>
                    <Container>
                        <SearchHeaderText size="2" as="h2" mt="2" mr="2">
                            Search results
                        </SearchHeaderText>
                    </Container>

                    <When condition={!isLoading && lastSearchedText}>
                        <Text size="5" as="p" my="0">
                            {searchResults.length} matches for `{lastSearchedText}`
                            <When condition={searchType}>
                                {' '}
                                of type {labelForValue(searchType!)}
                            </When>
                        </Text>
                        <When condition={searchResults.length}>
                            <Container flex="1" justifyContent="flex-end">
                                <Button
                                    size="small"
                                    onClick={executeAddSelection}
                                    disabled={selectedTitles.length === 0}
                                >
                                    Add ({selectedTitles.length}) selected
                                </Button>
                            </Container>
                        </When>
                    </When>
                </SearchResultsHeader>
            </When>

            <When condition={isLoading}>
                <Loader center size="normal" />
            </When>
            <When condition={!isLoading && searchResults.length}>
                <SearchResultsTableWrapper>
                    <TableNode tableInstance={table} />
                </SearchResultsTableWrapper>
                <When condition={searchResults.length > 20}>
                    <Container flex="1" justifyContent="flex-end" mt={1}>
                        <Button
                            size="small"
                            onClick={executeAddSelection}
                            disabled={selectedTitles.length === 0}
                        >
                            Add ({selectedTitles.length}) selected
                        </Button>
                    </Container>
                </When>
            </When>
        </SearchContainer>
    )
}

type useTitleSelectionTableOpts = {
    upstreamTitles: AssignmentTitle[]
    searchResults: WorkflowTitleSearch[]
    selectedTitles: WorkflowTitleSearch[]
    setSelectedTitles: React.Dispatch<React.SetStateAction<WorkflowTitleSearch[]>>
    addSelection: (selection: WorkflowTitleSearch[]) => void
}
const useTitleSelectionTable = ({
    searchResults,
    selectedTitles,
    upstreamTitles,
    setSelectedTitles,
    addSelection,
}: useTitleSelectionTableOpts): TableInstance<WorkflowTitleSearch> => {
    const upstreamTitlesRef = React.useRef(upstreamTitles)
    upstreamTitlesRef.current = upstreamTitles
    const searchResultsRef = React.useRef(searchResults)
    searchResultsRef.current = searchResults

    const toggleSelectedTitle = React.useCallback(
        (title: WorkflowTitleSearch, value?: boolean): void => {
            setSelectedTitles(selectedTitles => {
                const isSelected = !!selectedTitles.find(t => t.resourceId === title.resourceId)
                const shouldExist = typeof value !== 'undefined' ? value : !isSelected

                if (!isSelected && shouldExist) {
                    return [...selectedTitles, title]
                } else if (isSelected && !shouldExist) {
                    return selectedTitles.filter(t => t.resourceId !== title.resourceId)
                }

                return selectedTitles
            })
        },
        [setSelectedTitles],
    )

    const toggleAllSelectedTitles = React.useCallback((): void => {
        setSelectedTitles(selectedTitles => {
            const selectAll = selectedTitles.length === 0
            if (selectAll) {
                return searchResultsRef.current
            }
            return []
        })
    }, [setSelectedTitles])

    const selectedRowIds = React.useMemo<Record<string, boolean>>(() => {
        const result: Record<string, boolean> = {}
        for (const title of selectedTitles) {
            const rowId = searchResults.findIndex(t => t.resourceId === title.resourceId)
            if (rowId > -1) {
                result[rowId] = true
            }
        }
        return result
    }, [searchResults, selectedTitles])

    const getIsRowDisabled = (resourceId: string): boolean =>
        upstreamTitlesRef.current.map(t => t.resourceId).includes(resourceId)

    const columns = React.useMemo<Column<WorkflowTitleSearch>[]>(
        () => [
            {
                id: 'selection',
                disableSortBy: true,
                width: 10,
                Header: instance => (
                    <IndeterminateCheckbox
                        checked={instance.isAllRowsSelected}
                        indeterminate={
                            !instance.isAllRowsSelected &&
                            !!Object.keys(instance.state.selectedRowIds).length
                        }
                        onChange={toggleAllSelectedTitles}
                    />
                ),
                Cell: ({ row, row: { original: title } }: CellProps<WorkflowTitleSearch>) => (
                    <input
                        type="checkbox"
                        checked={row.isSelected}
                        disabled={upstreamTitlesRef.current
                            .map(t => t.resourceId)
                            .includes(title.resourceId)}
                        onChange={e => toggleSelectedTitle(title, e.target.checked)}
                    />
                ),
            },
            {
                id: 'title',
                disableSortBy: true,
                Header: 'Title',
                Cell: ({ row: { original: title } }: CellProps<WorkflowTitleSearch>) => (
                    <SearchResultTitle {...{ result: title.searchResult! }} />
                ),
                width: 1000,
            },
            {
                id: 'type',
                Header: 'Type',
                disableSortBy: true,
                accessor: t => t.cwmClassTypeLabel,
                Cell: ({ row: { original: title } }: CellProps<WorkflowTitleSearch>) => (
                    <Text size="5" variant="secondary" as="span" style={{ whiteSpace: 'nowrap' }}>
                        {title.cwmClassTypeLabel}
                    </Text>
                ),
                width: 100,
            },
            {
                id: 'actions',
                Header: 'Actions',
                disableSortBy: true,
                accessor: t => t.cwmClassTypeLabel,
                Cell: ({ row: { original: title } }: CellProps<WorkflowTitleSearch>) => (
                    <Container justifyContent="flex-end">
                        <Button
                            size="small"
                            onClick={() => addSelection([title])}
                            style={{ padding: '0.375rem 1rem' }}
                            disabled={getIsRowDisabled(title.resourceId)}
                        >
                            Add
                        </Button>
                    </Container>
                ),
                width: 60,
            },
        ],
        [addSelection, toggleAllSelectedTitles, toggleSelectedTitle],
    )

    return useTable(
        {
            columns,
            data: searchResults,
            useControlledState: React.useCallback(
                state => ({
                    ...state,
                    selectedRowIds,
                }),
                [selectedRowIds],
            ),
            getRowProps: ({ original: title }) => {
                const isDisabled = getIsRowDisabled(title.resourceId)
                return isDisabled ? { isDisabled, title: 'Already selected' } : undefined
            },
            onRowClick: ({ row }: TableRowComponentProps<WorkflowTitleSearch>): void => {
                toggleSelectedTitle(row.original)
            },
        },
        useRowSelect,
    )
}

const searchResultToWorkflowTitle = (s: SearchResult): WorkflowTitleSearch => {
    return {
        resourceTitle: getTitle(s.genomeObject),
        resourceId: s.id,
        cwmClassType: s.genomeObject.cwmClassType,
        cwmClassTypeLabel: s.genomeObject.cwmClassTypeLabel,
        searchResult: s,
    }
}

export default TitleSearch

import * as D from 'io-ts/lib/Decoder'
import config from 'shared/config'
import { useQuery, UseQueryResult } from 'react-query'
import { PartialMyIDUser, PartialMyIDUserDec } from '@genome-web-forms/server'
import { request } from '@genome-web-forms/common/api'
import { authGWF } from '../auth'
import { useUser } from 'auth/Auth'
import { MyIDUser } from '@genome-web-forms/common/auth'

export const usePartialMyIDUsersQuery = (): UseQueryResult<PartialMyIDUser[]> => {
    const user = useUser()
    return useQuery(['workflow-users'], () => fetchPartialMyIDUsers(user), {
        initialData: [],
    })
}

export const fetchPartialMyIDUsers = (user: MyIDUser): Promise<PartialMyIDUser[]> => {
    return request(
        D.array(PartialMyIDUserDec),
        authGWF(user, {
            url: `${config.urlGWFWorkflows}/users`,
        }),
    )
}

import React from 'react'
import { space, SpaceProps } from 'styled-system'
import styled, { css } from 'shared/theme'

import LoaderSVG from 'shared/components/Loader/LoadingSVG'

export type Size = 'small' | 'normal' | 'large'
export type Variant =
    | 'primary'
    | 'outline'
    | 'outlineNoBorders'
    | 'success'
    | 'danger'
    | 'danger-gray'
    | 'autofill'

// Button Sizes
const smallSize = css`
    padding: 0.375rem 1.5rem;
`
const normalSize = css`
    padding: 0.625rem 2rem;
`
const largeSize = css`
    padding: 0.75rem 3.75rem;
    font-size: 1.25rem;
    svg {
        width: 28px;
        height: 28px;
        right: 0.5rem;
        margin-top: -14px;
    }
`

// Variants
const primaryVariant = css<{ disabled?: boolean }>`
    color: ${props => props.theme.colors.white};
    background-color: ${props =>
        props.disabled ? props.theme.colors.border : props.theme.colors.primary};
`
const outlineVariant = css<{ disabled?: boolean }>`
    border: 2px solid
        ${props => (props.disabled ? props.theme.colors.border : props.theme.colors.primary)};
    color: ${props => (props.disabled ? props.theme.colors.border : props.theme.colors.primary)};
    background-color: transparent;
`

const outlineNoBordersVariant = css<{ disabled?: boolean }>`
    color: ${props => (props.disabled ? props.theme.colors.border : props.theme.colors.primary)};
    background-color: transparent;
`

const dangerVariant = css<{ disabled?: boolean }>`
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.danger};
`
const dangerGrayVariant = css<{ disabled?: boolean }>`
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.border};
    &:hover,
    &:focus,
    &:active {
        background-color: ${props => props.theme.colors.danger};
    }
`
const autofillVariant = css<{ disabled?: boolean }>`
    color: ${props => props.theme.colors.white};
    background-color: ${props =>
        props.disabled ? props.theme.colors.border : props.theme.colors.autofill};
`

const isLoading = css`
    svg {
        position: absolute;
        width: 20px;
        height: 20px;
        right: 4px;
        top: 50%;
        margin-top: -10px;
    }
`
type OwnProps = {
    size?: Size
    variant?: Variant
    isLoading?: boolean
}

export type ButtonProps = React.PropsWithRef<JSX.IntrinsicElements['button']> &
    SpaceProps &
    OwnProps
const StyledButton = styled.button<ButtonProps>`
    ${space}

    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    border: 0;
    font-size: 0.9375rem;
    border-radius: 2rem;
    line-height: 1.25rem;
    position: relative;
    margin: 0 0.25rem;
    ${props => props.isLoading && isLoading}
    ${props => {
        switch (props.size) {
            case 'small':
                return smallSize
            case 'normal':
                return normalSize
            case 'large':
                return largeSize
            default:
                return normalSize
        }
    }}
    ${props => {
        switch (props.variant) {
            case 'primary':
                return primaryVariant
            case 'outline':
                return outlineVariant
            case 'outlineNoBorders':
                return outlineNoBordersVariant
            case 'success':
                return primaryVariant
            case 'danger':
                return dangerVariant
            case 'autofill':
                return autofillVariant
            case 'danger-gray':
                return dangerGrayVariant
            default:
                return primaryVariant
        }
    }}
`

// TODO add forward ref
const Button: React.FC<ButtonProps> = ({
    isLoading = false,
    disabled = false,
    variant = 'primary',
    size = 'normal',
    onClick,
    children,
    ...restProps
}): React.ReactElement => {
    return (
        <StyledButton
            type="button"
            disabled={disabled}
            size={size}
            variant={variant}
            isLoading={isLoading}
            onClick={onClick}
            {...restProps}
        >
            {children}
            {isLoading && <LoaderSVG />}
        </StyledButton>
    )
}

export default Button

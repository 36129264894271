import React from 'react'
import { Formik, Form } from 'formik'
import get from 'lodash/get'
import fromPairs from 'lodash/fromPairs'

import styled from 'shared/theme'
import Autosave from 'shared/form/Autosave'
import Panel from 'shared/components/Panel'
import Text from 'shared/components/Text'
import StaticAttributeSelect from 'shared/components/Metadata/StaticAttributeSelect'
import { RecordedAttributes, ATTRIBUTE_META_LIST } from 'model/metadata/RecordedAttributes'
import { safeFormikData, SafeForFormik } from 'shared/util/formikSafe'
import DisplayGenreSelect from 'shared/components/Metadata/DisplayGenreSelect'
import OtherGenresSelect from 'shared/components/Metadata/OtherGenresSelect'
import {
    useWIPMetadataActor,
    useFormControlsEnabled,
    useViewMode,
    getWIPData,
    useCommentsActorRef,
} from 'shared/resource/ResourceMachineProvider'
import { usePreviewModeDiff } from 'shared/resource/ResourceMachineProvider'
import MetadataDiff from 'shared/components/Metadata/MetadataDiff'
import CommentIcon from 'shared/components/Icons/CommentIcon'

import { Metadata, metadataIsEmpty } from 'model/metadata/Metadata'
import LocationsSelect from './LocationsSelect'
import Loader from '../Loader'
import Button from '../Button'
import { flags } from 'shared/flags'
import CommentsSideOverlay from '../CommentsSideOverlay'
import LibrarianNotes from './MetadataDescription'
import { useSelector } from '@xstate/react'
import { StateFrom } from 'shared/types'

const Grid = styled.div`
    display: grid;
    grid-gap: 1.5rem;
`
const GridWithColumns = styled(Grid).attrs({})`
    grid-template-columns: 1fr 1fr 1fr;
    align-items: start;
    margin-top: 1.25rem;
    & + & {
        margin-top: 1rem;
    }
`

const AutofillUpperLip = styled.div`
    display: flex;
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${props => props.theme.colors.autofill};
    font-size: 0.875rem;
    line-height: 1.5rem;
    padding: 0.25rem 1.5rem;
    color: ${props => props.theme.colors.white};
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
`

const StyledForm = styled(Form)<{ $autofillMode?: boolean }>`
    overflow: hidden;
    min-height: calc(100vh - 210px);
    ${props => props.$autofillMode && `border: 5px solid ${props.theme.colors.autofill};`}
`
export const MetadataNode: React.FC = () => {
    const [state, send] = useWIPMetadataActor()
    const previewModeDiff = usePreviewModeDiff()

    const metadata = getWIPData(useViewMode(), state)
    const autofillEnabled = metadataIsEmpty(metadata)
    const { attributes, displayGenre, otherGenres, locations } = metadata
    const { safe: safeAttributes, safeToOriginal: safeAttributesToOriginal } =
        useAttributes(attributes)

    const formControlsEnabled = useFormControlsEnabled('metadata')

    const initialValues = React.useMemo(
        () => ({
            attributes: safeAttributes,
            displayGenre,
            otherGenres,
            locations,
        }),
        [displayGenre, otherGenres, safeAttributes, locations],
    )

    if (previewModeDiff) {
        return <MetadataDiff />
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={values => {
                const data: Metadata = {
                    ...values,
                    attributes: safeAttributesToOriginal(values.attributes),
                }
                send({ type: 'UPDATE', data })
            }}
        >
            <StyledForm $autofillMode={state.matches('autofill')}>
                {state.matches('autofill') && (
                    <AutofillUpperLip>
                        Autofill Mode
                        {state.matches('autofill.loading') && <Loader size="tiny" color="white" />}
                    </AutofillUpperLip>
                )}
                {formControlsEnabled && <Autosave />}
                <GridWithColumns>
                    <div />
                    <div />
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        }}
                    >
                        {flags.comments && <CommentsButton />}

                        {state.matches('editing') && (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    variant="autofill"
                                    onClick={() => send({ type: 'AUTOFILL_START' })}
                                    disabled={!autofillEnabled}
                                >
                                    Autofill Mode
                                </Button>
                            </div>
                        )}
                        {state.matches('autofill') && (
                            <div style={{ display: 'flex', justifyContent: 'around' }}>
                                <Button
                                    variant="outlineNoBorders"
                                    onClick={() => send({ type: 'AUTOFILL_CANCEL' })}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => send({ type: 'AUTOFILL_COMMIT' })}
                                    disabled={state.matches('autofill.loading')}
                                >
                                    Save changes
                                </Button>
                            </div>
                        )}
                    </div>
                </GridWithColumns>
                <GridWithColumns>
                    <div>
                        <Panel mb={2}>
                            <Text as="h3" size="3" variant="secondary" mb="2">
                                Genres
                            </Text>
                            <DisplayGenreSelect
                                formControlsEnabled={formControlsEnabled}
                                name="displayGenre"
                            />
                            <OtherGenresSelect
                                formControlsEnabled={formControlsEnabled}
                                name="otherGenres"
                            />
                        </Panel>
                        <Panel>
                            <Text as="h3" size="3" variant="secondary" mb="2">
                                Locations
                            </Text>
                            <LocationsSelect
                                name="locations"
                                formControlsEnabled={formControlsEnabled}
                            />
                        </Panel>
                    </div>
                    <Panel>
                        <Text as="h3" size="3" variant="secondary" mb="2">
                            Keywords
                        </Text>
                        {ATTRIBUTE_META_LIST.map(attributeMeta => (
                            <StaticAttributeSelect
                                key={attributeMeta.type}
                                {...{ formControlsEnabled, attributeMeta }}
                            />
                        ))}
                    </Panel>
                    <div>
                        {flags.comments && <CommentsSideOverlay />}
                        <LibrarianNotes />
                    </div>
                </GridWithColumns>
            </StyledForm>
        </Formik>
    )
}

function CommentsButton(): JSX.Element {
    const commentsActorRef = useCommentsActorRef()

    const commentsCount = useSelector(
        commentsActorRef,
        React.useCallback(
            (state: StateFrom<typeof commentsActorRef>) =>
                state.matches('loading') ? undefined : state.context.comments.length,
            [],
        ),
    )

    return (
        <CommentIcon
            role="button"
            aria-label="Toggle comments drawer"
            onClick={() => commentsActorRef.send({ type: 'TOGGLE_OPEN' })}
        >
            {commentsCount}
        </CommentIcon>
    )
}

function useAttributes(
    inputAttributes: RecordedAttributes | null,
): SafeForFormik<RecordedAttributes> {
    // Ensure that the input data has all expected fields populated with empty arrays
    const attributes = React.useMemo(() => {
        return fromPairs(
            ATTRIBUTE_META_LIST.map(({ type }) => {
                return [type, get(inputAttributes, [type], [])]
            }),
        )
    }, [inputAttributes])

    return React.useMemo(() => safeFormikData(attributes), [attributes])
}

export default React.memo(MetadataNode)

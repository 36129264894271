import { cwmClassTypeToLabel } from '@genome-web-forms/common/model/CWMClassType'
import { Workflow, WorkflowHelper } from '@genome-web-forms/server'
import {
    useSelfAssignWorkflow,
    useWorkflowsQuery,
    WorkflowAssignmentTableType,
} from 'api/workflow/workflow'
import capitalize from 'lodash/capitalize'
import words from 'lodash/words'
import React from 'react'
import { CellProps, Column } from 'react-table'
import { workflowLink } from 'routing/routes'
import Button from 'shared/components/Button'
import Container from 'shared/components/Container'
import Loader from 'shared/components/Loader'
import MediumDate from 'shared/components/MediumDate'
import NoWrap from 'shared/components/NoWrap'
import { SearchResultLink, WorkflowSearchContainer } from 'shared/components/search'
import SearchInput from 'shared/components/SearchInput'
import Table, { CheckboxFilter } from 'shared/components/Table'
import TableHeaderContent from 'shared/components/Table/TableHeaderContent'
import Text from 'shared/components/Text'
import { routeTo } from 'model/search/routeTo'
import { notification } from 'shared/notification'

const columns: Column<Workflow>[] = [
    {
        id: 'title',
        Header: 'Title',
        accessor: 'resourceTitle',
        width: 1000,
        Cell: ({ row: { original } }: CellProps<Workflow>) => {
            // TODO remove this when backend fixes itself
            try {
                return (
                    <SearchResultLink to={workflowLink(original)}>
                        <span style={{ textDecoration: 'underline' }}>
                            {original.resourceTitle} - {WorkflowHelper.title(original)}
                        </span>
                    </SearchResultLink>
                )
            } catch (e) {
                return original.resourceTitle
            }
        },
    },
    {
        id: 'type',
        Header: 'Title Type',
        accessor: 'cwmClassType',
        Cell: ({ row: { original } }: CellProps<Workflow>) => (
            <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                {cwmClassTypeToLabel(original.cwmClassType)}
            </Text>
        ),
        width: 1,
    },
    {
        id: 'task',
        Header: 'Assignment Type',
        accessor: (original: Workflow) => original.workflowConfig.task,
        filter: 'checkbox',
        Filter: CheckboxFilter,
        Cell: ({ row: { original } }: CellProps<Workflow>) => (
            <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                {words(original.workflowConfig.task as string)
                    .map(capitalize)
                    .join(' / ')}
            </Text>
        ),
        width: 1,
    },
    {
        id: 'dateAssigned',
        Header: 'Date Assigned',
        accessor: (original: Workflow | undefined) => {
            const transitions = original?.transitions || []
            const createdAt =
                transitions.length > 0 ? transitions[transitions.length - 1].createdAt : null
            return createdAt
        },
        filter: 'checkbox',
        Filter: CheckboxFilter,
        Cell: ({ row: { original } }: CellProps<Workflow>) => {
            const transitions = original.transitions || []
            const createdAt =
                transitions.length > 0 ? transitions[transitions.length - 1].createdAt : null
            return (
                <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                    {createdAt && <MediumDate date={createdAt} />}
                </Text>
            )
        },
        width: 1,
    },
    {
        id: 'deadlineAt',
        Header: 'Due Date',
        accessor: 'deadlineAt',
        Cell: ({ row: { original } }: CellProps<Workflow>) => (
            <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                {original.deadlineAt && <MediumDate date={original.deadlineAt} />}
            </Text>
        ),
        width: 1,
    },
    {
        Header: 'Status',
        accessor: 'priority',
        filter: 'checkbox',
        Filter: CheckboxFilter,
        sortDescFirst: true,
        Cell: ({ row: { original } }: CellProps<Workflow>) => (
            <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                {original.state}
            </Text>
        ),
        width: 1,
    },
]

const actionsForType = (type: WorkflowAssignmentTableType): Column<Workflow> => {
    let Cell: (props: CellProps<Workflow>) => JSX.Element | null = () => null

    switch (type) {
        case 'TAGGING':
        case 'QA': {
            Cell = ({ row: { original } }) => {
                const selfAssignWorkflow = useSelfAssignWorkflow()
                return (
                    <Button
                        size="small"
                        onClick={() => {
                            selfAssignWorkflow.mutate(original, {
                                onSuccess: () => {
                                    notification.success('Task was self assigned successfully')
                                },
                            })
                        }}
                    >
                        <NoWrap>Self-Assign</NoWrap>
                    </Button>
                )
            }
            break
        }
        case 'SELF': {
            Cell = ({ row: { original } }) => {
                return (
                    <SearchResultLink to={routeTo(original)}>
                        <Button size="small">
                            <NoWrap>Go to title</NoWrap>
                        </Button>
                    </SearchResultLink>
                )
            }
        }
    }

    return {
        Header: 'Actions',
        Cell,
    }
}

type AssignmentTableProps = {
    type: WorkflowAssignmentTableType
}

export function AssignmentTable({ type }: AssignmentTableProps): React.ReactElement {
    const { isLoading, data = [] } = useWorkflowsQuery(type)
    const [searchText, setSearchText] = React.useState('')

    const resolvedColumns = React.useMemo(() => [...columns, actionsForType(type)], [type])

    return (
        <WorkflowSearchContainer>
            <Container mb={2} alignItems="center">
                <TableHeaderContent>
                    <Text as="h3" size="2" my="2">
                        Search
                    </Text>

                    <SearchInput value={searchText} onChange={e => setSearchText(e.target.value)} />
                </TableHeaderContent>
            </Container>

            {isLoading ? (
                <Loader center size="normal" />
            ) : (
                <Table
                    {...{
                        columns: resolvedColumns,
                        data,
                        searchText,
                        initialState: { sortBy: [{ desc: true, id: 'createdDate' }] },
                    }}
                />
            )}
        </WorkflowSearchContainer>
    )
}

export default AssignmentTable

import React from 'react'
import PageLayout from 'pages/util/PageLayout'
import { useDeleteWorkflow, useWorkflowsQuery } from 'api/workflow/workflow'
import Table, { CheckboxFilter } from 'shared/components/Table'
import Text from 'shared/components/Text'
import { Column, CellProps } from 'react-table'
import { Workflow } from '@genome-web-forms/server'
import { SearchContainer, SearchResultLink } from 'shared/components/search'
import { cwmClassTypeToLabel } from '@genome-web-forms/common/model/CWMClassType'
import capitalize from 'lodash/capitalize'
import words from 'lodash/words'
import Loader from 'shared/components/Loader'
import Container from 'shared/components/Container'
import SearchInput from 'shared/components/SearchInput'
import TableHeaderContent from 'shared/components/Table/TableHeaderContent'
import { WorkflowPriorities } from 'model/workflow/WorkflowPriority'
import MediumDate from 'shared/components/MediumDate'
import Button from 'shared/components/Button'
import { flags } from 'shared/flags'
import { routeTo } from 'model/search/routeTo'

const columns: Column<Workflow>[] = [
    {
        Header: 'Title',
        accessor: 'resourceTitle',
        width: 1000,
        Cell: ({ row: { original } }: CellProps<Workflow>) => {
            // TODO remove this when backend fixes itself
            try {
                return (
                    <SearchResultLink to={routeTo(original)}>
                        <span style={{ textDecoration: 'underline' }}>
                            {original.resourceTitle}
                        </span>
                    </SearchResultLink>
                )
            } catch (e) {
                return original.resourceTitle
            }
        },
    },
    {
        id: 'type',
        Header: 'Type',
        accessor: 'cwmClassType',
        Cell: ({ row: { original } }: CellProps<Workflow>) => (
            <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                {cwmClassTypeToLabel(original.cwmClassType)}
            </Text>
        ),
        width: 1,
        // filter: 'checkbox',
        // Filter: CheckboxFilter,
    },
    {
        id: 'task',
        Header: 'Task',
        accessor: original => original.workflowConfig.task,
        filter: 'checkbox',
        Filter: CheckboxFilter,
        Cell: ({ row: { original } }: CellProps<Workflow>) => (
            <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                {words(original.workflowConfig.task as string)
                    .map(capitalize)
                    .join(' / ')}
            </Text>
        ),
        width: 1,
    },
    {
        Header: 'Assignee',
        accessor: 'assignee',
        filter: 'checkbox',
        Filter: CheckboxFilter,
        width: 1,
    },
    {
        Header: 'Priority',
        accessor: 'priority',
        filter: 'checkbox',
        Filter: CheckboxFilter,
        sortType: ({ original: a }, { original: b }) => {
            if (!a.priority) {
                return -1
            }
            if (!b.priority) {
                return 1
            }
            return WorkflowPriorities.indexOf(a.priority) - WorkflowPriorities.indexOf(b.priority)
        },
        sortDescFirst: true,
        Cell: ({ row: { original } }: CellProps<Workflow>) => capitalize(original.priority ?? ''),
        width: 1,
    },
    {
        Header: 'Publish By Date',
        accessor: 'deadlineAt',
        Cell: ({ row: { original } }: CellProps<Workflow>) => (
            <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                {original.deadlineAt ? <MediumDate date={original.deadlineAt} /> : null}
            </Text>
        ),
        width: 1,
    },
    {
        Header: 'Creation Date',
        accessor: 'createdAt',
        id: 'createdDate',
        Cell: ({ row: { original } }: CellProps<Workflow>) => (
            <Text size="5" style={{ whiteSpace: 'nowrap' }}>
                {<MediumDate date={original.createdAt} />}
            </Text>
        ),
        width: 1,
    },
    {
        Header: 'Actions',
        Cell: ({ row: { original } }: CellProps<Workflow>) => {
            const deleteWorkflow = useDeleteWorkflow()
            if (!flags.workflowsDelete) {
                return
            }
            return (
                <Button
                    size="small"
                    variant="danger-gray"
                    onClick={() => {
                        deleteWorkflow.mutate(original)
                    }}
                >
                    Delete
                </Button>
            )
        },
    },
]

const WorkflowsList: React.FC = () => {
    const { isLoading, data = [] } = useWorkflowsQuery()
    const [searchText, setSearchText] = React.useState('')

    return (
        <PageLayout>
            <SearchContainer>
                <Container mb={2} alignItems="center">
                    <TableHeaderContent>
                        <Text as="h3" size="2" my="2">
                            Workflows List
                        </Text>

                        <SearchInput
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                        />
                    </TableHeaderContent>
                </Container>

                {isLoading ? (
                    <Loader center size="normal" />
                ) : (
                    <Table
                        {...{
                            columns,
                            data,
                            searchText,
                            initialState: { sortBy: [{ desc: true, id: 'createdDate' }] },
                        }}
                    />
                )}
            </SearchContainer>
        </PageLayout>
    )
}

export default WorkflowsList

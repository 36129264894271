import React from 'react'
import { useField } from 'formik'
import FormikSelect, { FormikSelectProps } from 'shared/components/Select/FormikSelect'
import { usePartialMyIDUsersQuery } from 'api/workflow/partialMyIDUsers'
import { ROLE_NAME_TAGGER } from '@genome-web-forms/server'

const AssigneeSelect: React.FC<FormikSelectProps<{ value: string; label: string }>> = props => {
    const { data: partialMyIDUsers = [], isLoading } = usePartialMyIDUsersQuery()
    const options = React.useMemo<{ value: string; label: string }[]>(
        () =>
            partialMyIDUsers
                .filter(pu => pu.roles.some(role => role.name === ROLE_NAME_TAGGER))
                .map(pu => ({
                    value: pu['relationship.employeeId'],
                    label: `${pu.given_name} ${pu.family_name} (${pu['relationship.employeeId']})`,
                })),
        [partialMyIDUsers],
    )
    const [{ value }, , { setValue }] = useField<string | null>(props.name)

    return (
        <FormikSelect
            {...props}
            options={options}
            isLoading={isLoading}
            isClearable={true}
            value={options.find(o => o.value === value)}
            onChange={
                ((value?: { value: string } | null) => setValue(value ? value.value : null)) as any
            }
        />
    )
}

export default AssigneeSelect

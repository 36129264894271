import React from 'react'
import { WIPDataType } from 'model/WIP'
import { useLockedWIPs, useResourceMachine } from 'shared/resource/ResourceMachineProvider'
import Button from 'shared/components/Button'
import TextualButton from 'shared/components/TextualButton'
import { Formik, Form } from 'formik'
import Container from 'shared/components/Container'
import CheckboxInput from 'shared/components/CheckboxRadioInput/Checkbox'
import capitalize from 'lodash/capitalize'

import {
    AlertDialog,
    AlertDialogLabel,
    AlertDialogButtons,
    AlertDialogDescription,
} from 'shared/components/AlertModal'

import WIPInfo from './WIPInfo'
import styled from 'shared/theme'

const PaddedButton = styled(Button)`
    padding: 0.375rem 1.25rem;
`

export const StartEditingControl: React.FC = () => {
    const [showModal, setShowModal] = React.useState(false)
    const lockedDataTypes = useLockedWIPs().map(wip => wip.dataType)
    const [state, send] = useResourceMachine()
    return (
        <Container alignItems="center">
            {lockedDataTypes.length ? (
                <Button
                    type="button"
                    onClick={() => send({ type: 'START_EDITING', selection: lockedDataTypes })}
                    isLoading={state.matches('aquiringLocks')}
                    disabled={state.matches('aquiringLocks')}
                >
                    Continue Editing
                </Button>
            ) : (
                <>
                    <Button type="button" onClick={() => setShowModal(true)}>
                        Start Editing
                    </Button>
                    {showModal && <StartEditingModal closeModal={() => setShowModal(false)} />}
                </>
            )}

            {state.matches({ idle: 'readonly' }) && (
                <PaddedButton
                    title="Preview the changes in the scratch database"
                    variant="outlineNoBorders"
                    onClick={() => send({ type: 'SET_VIEW_MODE', viewMode: 'preview' })}
                >
                    Preview
                </PaddedButton>
            )}
        </Container>
    )
}

const StartEditingModal: React.FC<{ closeModal: Function }> = ({ closeModal }) => {
    const [state, send] = useResourceMachine()
    const cancelRef = React.createRef<HTMLButtonElement>()

    const isProcessing = state.matches('aquiringLocks')

    const availableTypes = state.context.wips
        .filter(ref => ref.state.matches('reading'))
        .map(ref => ref.state.context.dataType)
    const checkboxList = state.context.wips.map(ref => {
        const dataType = ref.state.context.dataType

        return (
            <CheckboxInput
                key={dataType}
                name="selection"
                value={dataType}
                disabled={
                    isProcessing || ref.state.matches({ reading: { loaded: 'ownedByOther' } })
                }
            >
                <Container alignItems="center">
                    <span>{capitalize(dataType)}:</span>
                    <WIPInfo dataType={dataType} />
                </Container>
            </CheckboxInput>
        )
    })

    const initialValues: { selection: WIPDataType[] } = { selection: [] }

    const onSubmit = ({ selection }: { selection: WIPDataType[] }): void => {
        send({ type: 'START_EDITING', selection })
    }

    return (
        <AlertDialog leastDestructiveRef={cancelRef}>
            <Formik {...{ initialValues, onSubmit }}>
                {({ values, setValues }) => (
                    <Form>
                        <AlertDialogLabel>
                            Which tabs do you want to lock for editing?
                        </AlertDialogLabel>
                        <AlertDialogDescription>Select the tabs to edit:</AlertDialogDescription>
                        <Container mt={3} mb="2" flexDirection="column">
                            {checkboxList}

                            <div style={{ display: 'flex' }}>
                                <Button
                                    variant="outline"
                                    type="button"
                                    size="small"
                                    disabled={isProcessing}
                                    onClick={() =>
                                        setValues({
                                            selection: [...availableTypes],
                                        })
                                    }
                                >
                                    Select All
                                </Button>
                            </div>
                        </Container>

                        <AlertDialogButtons>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={values.selection.length < 1 || isProcessing}
                                isLoading={isProcessing}
                            >
                                Start Editing
                            </Button>
                            <TextualButton
                                type="button"
                                onClick={() => closeModal()}
                                ref={cancelRef}
                                disabled={isProcessing}
                            >
                                Cancel
                            </TextualButton>
                        </AlertDialogButtons>
                    </Form>
                )}
            </Formik>
        </AlertDialog>
    )
}

export default StartEditingControl

import React from 'react'
import styled from 'shared/theme'
import { Formik, Form } from 'formik'
import Button from 'shared/components/Button'
import TextualButton from 'shared/components/TextualButton'
import Badge from 'shared/components/Badge'
import CheckboxInput from 'shared/components/CheckboxRadioInput/Checkbox'
import {
    AlertDialog,
    AlertDialogLabel,
    AlertDialogButtons,
    AlertDialogDescription,
} from 'shared/components/AlertModal'
import { PERMISSION_PUBLISH } from '@genome-web-forms/common/auth'
import { UserHasPermission } from 'shared/components/UserHasPermission'
import Container from 'shared/components/Container'
import { useLockedWIPs, useResourceMachine } from 'shared/resource/ResourceMachineProvider'
import { PublishProperty } from 'shared/resource/publish.machine'
import { isUpdatingWIP } from 'shared/resource/resource.machine'
import capitalize from 'lodash/capitalize'
import fromPairs from 'lodash/fromPairs'

const StyledButton = styled(Button)`
    margin-right: 1.5rem;
`

export const PublishControl: React.FC = (): React.ReactElement => {
    const [state] = useResourceMachine()
    const [showModal, setShowModal] = React.useState(false)
    return (
        <>
            <UserHasPermission permission={PERMISSION_PUBLISH}>
                <StyledButton
                    onClick={() => setShowModal(true)}
                    isLoading={state.matches('rawPublising')}
                    disabled={!state.matches('editing') || isUpdatingWIP(state)}
                >
                    Publish
                </StyledButton>
            </UserHasPermission>
            {showModal && <PublishModal closeModal={() => setShowModal(false)} />}
        </>
    )
}

const PublishModal: React.FC<{ closeModal: Function }> = ({ closeModal }): React.ReactElement => {
    const [state, send] = useResourceMachine()

    const cancelRef = React.createRef<HTMLButtonElement>()

    const dataTypes = state.context.wips.map(ref => ref.state.context.dataType)
    const lockedDataTypes = useLockedWIPs().map(wip => wip.dataType)
    const locked = fromPairs(
        dataTypes.map(dataType => [dataType, lockedDataTypes.includes(dataType)]),
    )
    const checkboxList = dataTypes.map(dataType => (
        <CheckboxInput
            key={dataType}
            name="publishProperties"
            value={dataType}
            disabled={!locked[dataType]}
        >
            <span>{capitalize(dataType)}:</span>{' '}
            {locked[dataType] ? <BadgeCanPublish /> : <BadgeCannotPublish />}
        </CheckboxInput>
    ))

    const initialValues: { publishProperties: PublishProperty[] } = { publishProperties: [] }

    return (
        <AlertDialog leastDestructiveRef={cancelRef}>
            <Formik
                initialValues={initialValues}
                onSubmit={({ publishProperties }) => {
                    send({ type: 'PUBLISH', publishProperties })
                    closeModal()
                }}
            >
                {({ values, setValues }) => (
                    <Form>
                        <AlertDialogLabel>
                            Are you sure you want to publish your data?
                        </AlertDialogLabel>
                        <AlertDialogDescription>
                            Select the data to be published:
                        </AlertDialogDescription>
                        <Container mt={3} mb="2" flexDirection="column">
                            {checkboxList}

                            <div style={{ display: 'flex' }}>
                                <Button
                                    variant="outline"
                                    type="button"
                                    size="small"
                                    onClick={() => {
                                        const publishProperties = Object.keys(locked).filter(
                                            key => locked[key],
                                        ) as PublishProperty[]

                                        setValues({ publishProperties })
                                    }}
                                >
                                    Select All
                                </Button>
                            </div>
                        </Container>

                        <AlertDialogButtons>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={values.publishProperties.length === 0}
                            >
                                Publish
                            </Button>
                            <TextualButton
                                type="button"
                                onClick={() => closeModal()}
                                ref={cancelRef}
                            >
                                Cancel
                            </TextualButton>
                        </AlertDialogButtons>
                    </Form>
                )}
            </Formik>
        </AlertDialog>
    )
}

const BadgeCanPublish: React.FC = () => <Badge variant="success">You can publish this tab.</Badge>
const BadgeCannotPublish: React.FC = () => (
    <Badge variant="secondary">You cannot publish a tab you did not lock.</Badge>
)

export default PublishControl

import React from 'react'
import TitleSearch from './TitleSearch'
import styled from 'shared/theme'
import Text from 'shared/components/Text'
import Table from 'shared/components/Table'
import Button from 'shared/components/Button'
import Container from 'shared/components/Container'
import { Column, CellProps } from 'react-table'
import { useInterpreter, useSelector } from './CreateAssignmentProvider'
import { AssignmentTitle } from 'model/workflow/CreateWorkflowRequest'
import { SearchResult } from 'model/search/SearchResult'
import CreateAssignmentsStepper from './CreateAssignmentsStepper'

const Wrapper = styled(Container)<{ hidden: boolean }>`
    max-width: 70%;
    margin: 40px auto 0;
    display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
`

type WorkflowTitleWithSearch = AssignmentTitle & { searchResult: SearchResult }

const TitleSelection: React.FC = () => {
    const service = useInterpreter()
    const selectedTitles = useSelector(React.useCallback(state => state.context.selection, [])).map(
        s => s.title,
    )

    const columns = React.useMemo<Column<AssignmentTitle>[]>(
        () => [
            {
                id: 'title',
                disableSortBy: true,
                Header: 'Title',
                Cell: ({ row: { original: title } }: CellProps<AssignmentTitle>) =>
                    title.resourceTitle,
                width: 1000,
            },
            {
                id: 'type',
                Header: 'Type',
                disableSortBy: true,
                accessor: t => t.cwmClassTypeLabel,
                Cell: ({ row: { original: title } }: CellProps<WorkflowTitleWithSearch>) => (
                    <Text size="5" variant="secondary" as="span" style={{ whiteSpace: 'nowrap' }}>
                        {title.cwmClassTypeLabel}
                    </Text>
                ),
                width: 100,
            },
            {
                id: 'add',
                Header: 'Actions',
                disableSortBy: true,
                Cell: ({ row: { original: title } }: CellProps<AssignmentTitle>) => (
                    <Container justifyContent="flex-end">
                        <Button
                            size="small"
                            onClick={() => service.send({ type: 'REMOVE_SELECTED_TITLE', title })}
                            style={{ padding: '0.375rem 1rem' }}
                        >
                            Remove
                        </Button>
                    </Container>
                ),
                width: 60,
            },
        ],
        [service],
    )

    return (
        <>
            <CreateAssignmentsStepper activeStep={1}>
                <Container mt={2}>
                    <Container flex="1"></Container>

                    <Container>
                        <Button
                            type="button"
                            size="large"
                            disabled={selectedTitles.length === 0}
                            onClick={() => service.send({ type: 'GO_TO_CONFIGURATION' })}
                        >
                            Next
                        </Button>
                    </Container>
                </Container>
            </CreateAssignmentsStepper>

            <Wrapper
                flexDirection="column"
                justifyContent="center"
                hidden={selectedTitles.length === 0}
            >
                <Container>
                    <Container>
                        <Text size="1" as="h1">
                            Selected Titles
                        </Text>
                    </Container>
                </Container>

                <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                    <Table
                        columns={columns}
                        data={selectedTitles}
                        emptyMessage={
                            <Container flexDirection="column" alignItems="center">
                                <Text size="1" as="h2" mt="3" mb="2">
                                    Search below to select a title
                                </Text>
                            </Container>
                        }
                    />
                </div>
            </Wrapper>

            <TitleSearch />
        </>
    )
}

export default TitleSelection

import { ResourceRequest } from 'api/fetch/fetchResource'
import {
    createMetadataDescriptionMutation,
    createMetadataDescriptionObserver,
} from 'api/metadataDescription'
import { MetadataDescription } from 'model/MetadataDescription'
import { isErrorWithData, isConflictError } from '@genome-web-forms/common/error'
import { customSerialize } from 'shared/util/customSerialize'
import { send } from 'xstate'
import { createModel } from 'xstate/lib/model'
import { hasPermission } from 'shared/components/UserHasPermission'
import { PERMISSION_WRITE } from '@genome-web-forms/common/auth'

type MetadataDescriptionErrors = {
    [K in '__general' | keyof MetadataDescription]?: Array<string>
} & {
    __updatedWhileEditing?: string
}

type Context = ResourceRequest & {
    editContent: string
    metadataDescription: MetadataDescription | null
    errors: MetadataDescriptionErrors
}
const model = createModel(
    {
        user: null!,
        resourceId: null!,
        resourceType: null!,
        metadataDescription: null,
        editContent: '',
        errors: {},
    } as Context,
    {
        events: {
            UPDATE_DATA: (metadataDescription: MetadataDescription) => ({ metadataDescription }),
            BACKEND_ERROR: ({
                errors,
                metadataDescription,
            }: {
                errors: MetadataDescriptionErrors
                metadataDescription?: MetadataDescription
            }) => ({ errors, metadataDescription }),
            SAVE_ERROR_CONFLICT: ({
                errors,
                metadataDescription,
            }: {
                errors: MetadataDescriptionErrors
                metadataDescription?: MetadataDescription
            }) => ({ errors, metadataDescription }),

            NO_DESCRIPTION: () => ({}),

            EDIT_START: () => ({}),
            EDIT_CANCEL: () => ({}),
            EDIT_COMMIT: (editContent: string) => ({ editContent }),
            LOAD_RETRY: () => ({}),

            SAVE_SUCCESS: (metadataDescription: MetadataDescription) => ({ metadataDescription }),
        },
    },
)
export const events = model.events
const assignData = model.assign(
    (_, { metadataDescription }: { metadataDescription: MetadataDescription }) => ({
        metadataDescription,
    }),
    'UPDATE_DATA',
)
const assignErrors = model.assign<'BACKEND_ERROR' | 'SAVE_ERROR_CONFLICT'>(
    (_, { errors, metadataDescription }) => ({ errors, metadataDescription }),
)
const assignEditContentFromCurrent = model.assign({
    editContent: ctx => ctx.metadataDescription?.content ?? '',
})
const assignEditContent = model.assign(
    {
        editContent: (_, { editContent }) => editContent,
    },
    'EDIT_COMMIT',
)

const updatedWhileEditing = model.assign(
    {
        errors: (
            { errors, metadataDescription: currentMetadataDescription },
            { metadataDescription },
        ) =>
            currentMetadataDescription?.lockVersion !== metadataDescription.lockVersion
                ? {
                      ...errors,
                      __updatedWhileEditing: metadataDescription.content,
                  }
                : errors,
    },
    'UPDATE_DATA',
)

const machine = model.createMachine(
    {
        id: 'resource-metadata-description',
        initial: 'loading',
        context: customSerialize(model.initialContext, {
            user: user => user['relationship.employeeId'],
        }),
        invoke: {
            id: 'metadata-description-query-observer',
            src: ctx => (sendBack, receive) => {
                const obs = createMetadataDescriptionObserver(ctx)
                const unsub = obs.subscribe(({ data, status }) => {
                    if (status === 'success') {
                        if (data == null) {
                            sendBack(model.events.NO_DESCRIPTION())
                        } else {
                            sendBack(model.events.UPDATE_DATA(data))
                        }
                    }

                    if (status === 'error') {
                        sendBack(
                            model.events.BACKEND_ERROR({
                                errors: {
                                    __general: [
                                        'The data was changed by someone else before you made your change.',
                                    ],
                                },
                            }),
                        )
                    }
                })

                receive(event => event.type === 'REFETCH' && obs.refetch())

                return unsub
            },
        },
        states: {
            loading: {
                entry: send('REFETCH', { to: 'metadata-description-query-observer' }),
                on: {
                    UPDATE_DATA: { actions: assignData, target: 'idle' },
                    NO_DESCRIPTION: { target: 'idle' },
                    BACKEND_ERROR: { actions: assignErrors as any, target: 'errorLoading' },
                },
            },
            errorLoading: {
                on: {
                    LOAD_RETRY: { target: 'loading' },
                },
            },
            idle: {
                on: {
                    UPDATE_DATA: { actions: assignData, target: 'idle' },
                    EDIT_START: { target: 'editing', cond: 'userIsAllowedToEdit' },
                },
            },
            editing: {
                entry: assignEditContentFromCurrent,
                on: {
                    EDIT_COMMIT: { target: 'saving', actions: assignEditContent },
                    EDIT_CANCEL: { target: 'idle' },
                    UPDATE_DATA: { actions: [updatedWhileEditing, assignData] },
                },
            },
            saving: {
                invoke: {
                    id: 'metadata-description-mutation-observer',
                    src: ctx => sendBack => {
                        let active = true
                        const mutation = createMetadataDescriptionMutation({
                            ...ctx,
                            metadataDescription: {
                                lockVersion: ctx.metadataDescription?.lockVersion ?? 0,
                                content: ctx.editContent,
                            },
                        })

                        mutation.execute().catch(error => {
                            if (!active) return
                            if (isConflictError(error)) {
                                if (isErrorWithData(error) && error.response.data.errors) {
                                    sendBack(model.events.SAVE_ERROR_CONFLICT(error.response.data))
                                } else {
                                    sendBack(
                                        model.events.SAVE_ERROR_CONFLICT({
                                            errors: { __general: ['Conflict error'] },
                                        }),
                                    )
                                }
                            } else if (isErrorWithData(error)) {
                                sendBack(model.events.BACKEND_ERROR(error.response.data))
                            } else {
                                sendBack(
                                    model.events.BACKEND_ERROR({
                                        errors: { __general: ['Unknown error'] },
                                    }),
                                )
                            }
                        })

                        return () => (active = false)
                    },
                },
                on: {
                    UPDATE_DATA: { target: 'idle', actions: assignData },
                    BACKEND_ERROR: { target: 'editing', actions: assignErrors as any },
                    SAVE_ERROR_CONFLICT: { target: 'editing', actions: assignErrors as any },
                },
            },
        },
    },
    {
        guards: {
            userIsAllowedToEdit: ctx => hasPermission(ctx.user, PERMISSION_WRITE),
        },
    },
)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createMetadataDescriptionMachine = (config: ResourceRequest) => {
    return machine.withContext({ ...model.initialContext, ...config })
}
